import { configureStore } from '@reduxjs/toolkit';
import channels from './channelSlice';
import users from './userSlice';
import workspace from './workspaceSlice';

export default configureStore({
  reducer: {
    channels,
    users,
    workspace,
  },
});
