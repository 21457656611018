import History from 'app/Services/Entities/History';
import Message from 'app/Services/Entities/Message';

function Conversation(slackConversation, id) {
  if (!id) throw new Error('Map error, missing channelId');

  const { messages: slackMessages, ...slackHistory } = slackConversation;
  const history = History(slackHistory, id);
  const messages = slackMessages.map((slackMessage) =>
    Message(slackMessage, id),
  );
  return { history, messages };
}

export default Conversation;
