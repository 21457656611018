/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { isFunction } from 'lodash';

export const useRequest = (service, { onSuccess, onError } = {}) => {
  const [inProcess, setProcess] = useState(false);
  const [error, setError] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const start = useCallback(
    (params, formMutator) => {
      // reset state
      setError(undefined);
      setProcess(true);
      setResponse(undefined);
      service(
        params,
        {
          onSuccess: (newResponse) => {
            setProcess(false);
            setResponse(newResponse);
            if (isFunction(onSuccess)) {
              onSuccess(newResponse);
            }
          },
          onError: (responseError) => {
            setProcess(false);
            setResponse(undefined);
            setError(responseError || 'error');
            if (isFunction(onError)) {
              onError(responseError);
            }
          },
        },
        formMutator,
      );
    },
    // disable as initialResults ref is not kept
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [service, setProcess],
  );
  return {
    start,
    inProcess,
    error,
    response,
  };
};

export const useRequestListener = (resourceMethod, { onSuccess, onError }) => {
  const resource = useRequest(resourceMethod);
  useEffect(() => {
    if (resource.result && isFunction(onSuccess)) {
      onSuccess(resource.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource.result]);

  useEffect(() => {
    if (resource.error && isFunction(onError)) {
      onError(resource.error);
    }
    // eslint-disable-next-line
  }, [resource.error]);
};

export const useDependentRequest = (
  localService,
  dependecyService,
  onCompleted,
) => {
  const localRequest = useRequest(localService);
  const depRequest = useRequest(dependecyService, {
    onSuccess: (depResponse) => {
      localRequest.start(depResponse);
    },
  });
  const isLoading = useMemo(
    () => localRequest.inProcess || depRequest.inProcess,
    [localRequest.inProcess, depRequest.inProcess],
  );
  const hasError = useMemo(() => localRequest.error || depRequest.error, [
    localRequest.error,
    depRequest.error,
  ]);

  useEffect(() => {
    if (depRequest.response && localRequest.response) {
      onCompleted(depRequest.response, localRequest.response);
    }
  }, [localRequest.response, depRequest.response]);

  return {
    isLoading,
    hasError,
    start: depRequest.start,
  };
};
