import { slackAPI } from 'common/utils/Http';
import User from 'app/Services/Entities/User';

const UsersList = async () => {
  const response = await slackAPI.post({
    url: 'users.list',
    body: {
      limit: '200',
    },
  });

  const users = (response.members || []).map(User);
  return users;
};

export default UsersList;
