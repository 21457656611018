function PostUserSigned() {
  const message = {
    event: 'PLUGIN_SIGN_SUCCESS',
  };
  if (window.opener) {
    window.opener.postMessage(message, '*');
  }
  window.close();
}

export default PostUserSigned;
