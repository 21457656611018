/* eslint-disable import/no-named-as-default */
import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRouteFactory from 'common/components/Routes/PrivateRoute';
import Home from 'app/Pages/Home';
import Http404Factory from 'app/Pages/404';
import Redirect from 'app/Pages/OAuth/Redirect';
import OAuthPermission from 'app/Pages/OAuth/Permission';
import SignIn from 'app/Pages/OAuth/SignIn';
import SlackConfig from 'app/Pages/SlackConfig';
import routerMap from './RouterMap';
import './styles.scss';

const PrivateRoute = PrivateRouteFactory(routerMap.signIn.path);
const Http404 = Http404Factory(routerMap.home.path);

function Main() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={routerMap.signIn.path} component={SignIn} />
          <Route
            exact
            path={routerMap.socialLogin.path}
            component={OAuthPermission}
          />
          <Route
            exact
            path={routerMap.oauthRedirect.path}
            component={Redirect}
          />
          <PrivateRoute exact path={routerMap.home.path} component={Home} />
          <PrivateRoute
            exact
            path={routerMap.config.path}
            component={SlackConfig}
          />
          <Http404 />
        </Switch>
      </Router>
    </div>
  );
}

export default hot(module)(Main);
