import { get } from 'lodash';

function Channel(slackChannel) {
  return {
    id: slackChannel.id,
    name: slackChannel.name_normalized,
    topic: get(slackChannel, 'topic.value'),
    description: get(slackChannel, 'purpose.value'),
    totalMembers: get(slackChannel, 'num_members'),
    conversation: null,
  };
}

export default Channel;
