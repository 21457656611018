/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import qs from 'query-string';
import appConfig from 'Config';
import { keys, isEmpty } from 'lodash';
import session from 'common/utils/Session';

class SlackClient {
  _template = (method) => async ({ url, body, notAuth = false }) => {
    const token = session.get('token');
    let resourceUrl = `${appConfig.get('domain')}${url}`;
    const params = {
      method,
    };
    if (method === 'POST') {
      params['Content-Type'] = 'application/x-www-form-urlencoded';
      const form = new FormData();
      const data = new URLSearchParams();
      if (!notAuth) form.append('token', token);
      if (!isEmpty(body)) {
        keys(body).forEach((itemKey) => {
          form.append(itemKey, body[itemKey]);
        });
      }
      for (const pair of form) {
        data.append(pair[0], pair[1], pair[2], pair[3]);
      }
      params.body = data;
    } else {
      // concatenation for GET methods
      const queryParams = qs.stringify(body);
      const qsWithAuth = !notAuth
        ? `?token=${token}&${queryParams}`
        : `?${queryParams}`;
      resourceUrl += qsWithAuth;
    }
    const response = await fetch(resourceUrl, params);
    const { ok, error, ...data } = await response.json();
    if (!ok) {
      throw new Error(error);
    }
    return data;
  };

  get = this._template('GET');

  post = this._template('POST');
}

export default SlackClient;
