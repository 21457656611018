import { slackAPI } from 'common/utils/Http';
import Workspace from 'app/Services/Entities/Workspace';

const TeamInfo = async () => {
  const response = await slackAPI.get({
    url: 'team.info',
  });

  const workspace = Workspace(response.team);
  return workspace;
};

export default TeamInfo;
