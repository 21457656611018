import React, { useLayoutEffect, useEffect, useRef } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { useRequest } from 'common/utils/Request';
import { getActiveChannelHistory, getUsersHash } from 'app/Store/selectors';
import ConversationMark from 'app/Services/Slack/Conversations/Mark';
import Message from './components/Message';

function Conversation() {
  const channelConversation = useSelector(getActiveChannelHistory);
  const usersHash = useSelector(getUsersHash);
  const userChannelMarkRequest = useRequest(ConversationMark);
  const lastScrollRef = useRef();
  useEffect(() => {
    const size = channelConversation.messages.length;
    const lastMessage = channelConversation.messages[size - 1];
    userChannelMarkRequest.start({
      channelId: channelConversation.id,
      lastMessageTs: lastMessage.ts,
    });
  }, [channelConversation.id]);

  useLayoutEffect(() => {
    setTimeout(() => {
      lastScrollRef.current.scrollIntoView({ behavior: 'auto' });
    }, 200);
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      lastScrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  }, [channelConversation.messages.length]);

  return (
    <div className="conversation-container text-dark bg-white border-shadow-light">
      {(channelConversation.messages || []).map((message) => (
        <Message
          key={message.ts}
          userId={message.userId}
          username={get(usersHash, `[${message.userId}].displayName`, '')}
          text={message.rawText}
          ts={message.ts}
          avatarUrl={get(usersHash, `[${message.userId}].avatar`, '')}
        />
      ))}
      <div style={{ float: 'left', clear: 'both' }} ref={lastScrollRef} />
    </div>
  );
}

Conversation.propTypes = {
  // channelId: string.isRequired,
};

export default Conversation;
