import { configAPI } from 'common/utils/Http';

async function RemoveTileChannel(tileChannel, responseHandlers) {
  try {
    await configAPI.delete({
      url: 'plugins/slack/channels',
      options: { data: tileChannel },
    });

    responseHandlers.onSuccess();
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default RemoveTileChannel;
