/* eslint-disable no-return-await */
import GetChannelsForTile from 'app/Services/TileChannel/getForTile';
import UserConversation from 'app/Services/Slack/Conversations/UserConversation';
import ConversationHistory from 'app/Services/Slack/Conversations/ConversationHistory';
import UsersList from 'app/Services/Slack/Users/List';
import TeamInfo from 'app/Services/Slack/Teams/Info';
import Session from 'common/utils/Session';

function getSelectedChannels(tileChannelsIds, slackChannels) {
  const selectedChannels = slackChannels.filter((channel) =>
    tileChannelsIds.includes(channel.id),
  );

  return selectedChannels;
}

async function loadChannelConversation(channel) {
  const conversation = await ConversationHistory(channel.id);
  return {
    ...channel,
    conversation,
  };
}

async function HomeView(tileId, responseHandlers) {
  try {
    const userId = Session.get('id');
    const [channelIds, channels, users, team] = await Promise.all([
      GetChannelsForTile(tileId),
      UserConversation(userId),
      UsersList(),
      TeamInfo(),
    ]);
    const selectedChannels = getSelectedChannels(channelIds, channels);
    const channelWithConversations = await Promise.all(
      selectedChannels.map(loadChannelConversation),
    );
    responseHandlers.onSuccess({
      channels: channelWithConversations,
      users,
      team,
    });
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default HomeView;
