import { get } from 'lodash';

function User(slackUser) {
  return {
    id: slackUser.id,
    name: slackUser.name,
    color: slackUser.color,
    displayName: get(slackUser, 'profile.display_name_normalized'),
    avatar: get(slackUser, 'profile.image_48'),
    isBot: slackUser.is_bot,
    deleted: slackUser.deleted,
  };
}

export default User;
