export const timeFromTimestamp = (timestamp) => {
  if (!timestamp) {
    return undefined;
  }

  const time = new Date(timestamp * 1000);
  const formattedTime = time.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return formattedTime;
};
