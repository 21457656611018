import { slackAPI } from 'common/utils/Http';

const ConversationMark = async ({ channelId, lastMessageTs }) => {
  try {
    await slackAPI.post({
      url: 'conversations.mark',
      body: {
        channel: channelId,
        ts: lastMessageTs,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export default ConversationMark;
