/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import cx from 'classnames';
import {
  Hash as BHash,
  PersonPlus,
  PersonSquare,
  PlusSquare,
  InfoCircle,
  Slack as BSlack,
  At as BAt,
  EmojiSmile,
  Paperclip,
  ArrowsAngleContract,
  ArrowsAngleExpand,
  CaretDownFill,
  CaretRightFill,
  PencilSquare,
  X as BX,
  Question as BQuestion,
  Pin as BPin,
  CursorFill,
  Type,
  ChevronDown as BChevronDown,
  ChevronLeft as BChevronLeft,
  Check as BCheck,
} from 'react-bootstrap-icons';

export const Check = BCheck;
export const ChevronDown = BChevronDown;
export const ChevronLeft = BChevronLeft;
export const FontType = Type;
export const Cursor = CursorFill;
export const Hash = BHash;
export const Add = PlusSquare;
export const AddPerson = PersonPlus;
export const DefaultAvatar = PersonSquare;
export const CaretRight = CaretRightFill;
export const CaretDown = CaretDownFill;
export const Info = InfoCircle;
export const Slack = BSlack;
export const At = BAt;
export const Emoji = EmojiSmile;
export const Attachment = Paperclip;
export const Expand = ArrowsAngleExpand;
export const Contract = ArrowsAngleContract;
export const Edit = PencilSquare;
export const X = BX;
export const Question = BQuestion;
export const Pin = (props) => (
  <BPin {...props} className={cx(props.classNames, 'rotate320')} />
);
