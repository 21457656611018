import { get } from 'lodash';

function History(slackHistory, channelId) {
  if (!channelId) throw new Error(`Map error, missing ${channelId}`);
  return {
    id: channelId,
    hasMore: slackHistory.has_more,
    hasUnread: get(slackHistory, 'unread_count_display', 0) > 0,
  };
}

export default History;
