/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import routerMap from 'app/Main/RouterMap';
import PostSignInRequest from 'app/Services/Events/PostSignInRequest';
import fromQS from 'common/utils/fromQS';
import DemoAuthorize from 'app/Services/DemoAuth';

function SignIn() {
  const history = useHistory();

  useEffect(() => {
    if (fromQS('demo_context')) {
      DemoAuthorize(fromQS('username'), () => {
        const redirectUrl = fromQS('location')
          .replace('<and>', '?')
          .replace('BASE', '/');
        history.push(redirectUrl);
      });
    } else {
      PostSignInRequest(routerMap.socialLogin.path);
    }
  }, []);
  return null;
}

export default SignIn;
