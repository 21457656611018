/* eslint-disable camelcase */
import appConfig from 'Config';
import { slackAPI } from 'common/utils/Http';
import session from 'common/utils/Session';

const Authorize = async ({ code }, responseHandlers) => {
  try {
    const response = await slackAPI.post({
      url: 'oauth.v2.access',
      body: {
        code,
        client_id: appConfig.get('slackId'),
        client_secret: appConfig.get('slackSecret'),
        redirect_uri: appConfig.get('slackOAUTH'),
      },
      notAuth: true,
    });
    const { authed_user, team } = response;
    session.save({
      token: authed_user.access_token,
      type: authed_user.token_type,
      scope: authed_user.scope,
      id: authed_user.id,
      teamId: team.id,
      teamName: team.name,
    });
    responseHandlers.onSuccess({
      authed_user,
      team,
    });
  } catch (error) {
    if (responseHandlers.onError) {
      responseHandlers.onError(error);
    }
  }
};

export default Authorize;
