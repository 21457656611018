import { configAPI } from 'common/utils/Http';

async function AddTileChannel(tileChannel, responseHandlers) {
  try {
    const { data: response } = await configAPI.post({
      url: 'plugins/slack/channels',
      body: tileChannel,
    });

    const mapped = response;
    responseHandlers.onSuccess({
      data: mapped.plugin,
    });
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default AddTileChannel;
