const routerMap = {
  home: {
    path: '/',
    getPath: () => '/',
  },
  socialLogin: {
    path: '/oauth/login',
    getPath: () => '/oauth/login',
  },
  signIn: {
    path: '/sign-in',
    getPath: () => '/sign-in',
  },
  oauthRedirect: {
    path: '/oauth/redirect',
    getPath: () => '/oauth/redirect',
  },
  config: {
    path: '/config',
    getPath: () => '/config',
  },
};

export default routerMap;
