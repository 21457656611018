import { isEmpty } from 'lodash';

export const getChannelCollection = (state) => {
  const collection = state.channels.ids
    .map((id) => state.channels.entities[id])
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  return collection;
};

export const getActiveChannel = (state) => {
  return state.channels.active
    ? state.channels.entities[state.channels.active]
    : {};
};

export const getActiveChannelHistory = (state) => {
  const activeChannel = getActiveChannel(state);
  if (isEmpty(activeChannel)) {
    return {
      id: null,
      history: {},
      messages: [],
    };
  }
  const messagesCollection = activeChannel.messagesIds
    .map((id) => activeChannel.messages[id])
    .reverse();
  return {
    id: activeChannel.id,
    history: activeChannel.history,
    messages: messagesCollection || [],
  };
};

export const getUsersHash = (state) => {
  return state.users.entities;
};

export const getWksp = (state) => {
  return state.workspace;
};
