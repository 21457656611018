/* eslint-disable no-param-reassign */
import { omit, valuesIn } from 'lodash';

function Observable() {
  let listeners = {};

  this.subscribe = ({ id, handler }) => {
    listeners[id] = handler;
  };
  this.unsubscribe = (id) => {
    listeners = omit(listeners, id);
  };

  this.emit = (message) => {
    console.log('about emitting to', listeners);
    valuesIn(listeners).forEach((handler) => handler(message));
  };
}

export default Observable;
