import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getActiveChannel } from 'app/Store/selectors';

import TextEditor from 'common/components/Inputs/TextEditor';
import { useRequest } from 'common/utils/Request';
import PostMessage from 'app/Services/Slack/Chat/PostMessage';

function Chat() {
  const activeChannel = useSelector(getActiveChannel);

  const postMessageRequest = useRequest(PostMessage, {
    onSuccess: () => {},
    onError: () => {},
  });

  const placeholder = useMemo(() => {
    return `Send a message to #${activeChannel.name}`;
  }, [activeChannel.name]);

  const handleSend = (message) => {
    postMessageRequest.start({ channelId: activeChannel.id, message });
  };

  return (
    <TextEditor
      key={activeChannel.id}
      placeholder={placeholder}
      onSend={handleSend}
    />
  );
}

Chat.propTypes = {};

export default Chat;
