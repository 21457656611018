import { configAPI } from 'common/utils/Http';

async function GetChannelsForTile(tileId) {
  const { data: response } = await configAPI.get({
    url: `plugins/slack/channels/${tileId}`,
  });
  return response.channel_ids;
}

export default GetChannelsForTile;
