/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import {
  Editor,
  EditorState,
  ContentState,
  convertToRaw,
  getDefaultKeyBinding,
  KeyBindingUtil,
} from 'draft-js';
import { func, string } from 'prop-types';
import cx from 'classnames';
import 'draft-js/dist/Draft.css';
import { Cursor } from 'common/icons';

import './styles.scss';

const { hasCommandModifier } = KeyBindingUtil;

const getEditorRawValue = (editorState) => {
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
  const rawvalue = blocks
    .map((block) => (!block.text.trim() && '\n') || block.text)
    .join('\n');
  return rawvalue;
};

function TextEditor({ initialContent, placeholder, onSend }) {
  const [editorState, setEditorState] = useState(
    initialContent
      ? EditorState.createWithContent(initialContent)
      : EditorState.createEmpty(),
  );
  const refContainer = useRef();

  useEffect(() => {
    setTimeout(() => {
      refContainer.current.focus();
    }, 0);
  }, []);
  const sendMessage = () => {
    const value = getEditorRawValue(editorState);
    onSend(value);
    const newEditorState = EditorState.push(
      editorState,
      ContentState.createFromText(''),
    );
    setEditorState(newEditorState);
    // needs timeout to execute after state is updated.
    setTimeout(() => {
      refContainer.current.focus();
    }, 0);
  };

  const handleClick = () => {
    sendMessage();
  };

  const handleKeyBinding = (event) => {
    if (!hasCommandModifier(event) && event.keyCode === 13) {
      sendMessage();
      return null;
    }
    return getDefaultKeyBinding(event);
  };

  return (
    <div className="text-editor-container border rounded border-dark">
      <div className="text-editor-input-container text-dark p-1">
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          placeholder={placeholder}
          keyBindingFn={handleKeyBinding}
          ref={refContainer}
        />
      </div>
      <div className="text-editor-controls d-flex justify-content-end px-2 py-1 rounded-bottom border-top border-light">
        <div
          onClick={handleClick}
          className={cx(
            'cursor-pointer p-2 rounded d-flex align-items-center',
            editorState.getCurrentContent().hasText()
              ? 'can-send text-white'
              : 'text-light',
          )}
        >
          <Cursor />
        </div>
      </div>
    </div>
  );
}

TextEditor.propTypes = {
  initialContent: string,
  onSend: func.isRequired,
  placeholder: string,
};

TextEditor.defaultProps = {
  initialContent: null,
  placeholder: 'Send a message',
};

export default TextEditor;
