/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'users',
  initialState: {
    ids: [],
    entities: {},
  },
  reducers: {
    addUserBatch: (state, action) => {
      const ids = [];
      const entities = action.payload.reduce((accum, user) => {
        ids.push(user.id);
        return {
          ...accum,
          [user.id]: user,
        };
      }, {});
      state.ids = ids;
      state.entities = entities;
    },
  },
});

export const { addUserBatch } = userSlice.actions;

export default userSlice.reducer;
