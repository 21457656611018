import React from 'react';
import { func, string } from 'prop-types';
import { ChevronLeft } from 'common/icons';

import './styles.scss';

function ChannelHeader({ name, topic, onGoBack }) {
  return (
    <div className="d-flex justify-content-start align-items-center text-dark bg-white">
      <div className="d-sm-none">
        <ChevronLeft className="mr-1 cursor-pointer" onClick={onGoBack} />
      </div>
      <div>
        <div className="channel-name">{`#${name}`}</div>
        <div className="channel-topic">{topic}</div>
      </div>
    </div>
  );
}

ChannelHeader.propTypes = {
  name: string.isRequired,
  onGoBack: func.isRequired,
  topic: string,
};

ChannelHeader.defaultProps = {
  topic: '',
};

export default ChannelHeader;
