import React from 'react';
import { func, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import session from 'common/utils/Session';
import fromQS from 'common/utils/fromQS';
// import routerMap from 'app/RouterMap';

function PrivateRouteHoC(signInPath) {
  function PrivateRoute({ component: Component, requiredRole, ...rest }) {
    const token = session.get('token');
    const isAuthenticated = token;

    const getRoute = (props) => {
      if (!isAuthenticated) {
        const params = window.location.search
          ? window.location.search
              .replace('?', '')
              .replace('&', '<and>')
              .replace('demo_context=true', '')
          : '';
        const demoContext = fromQS('demo_context');
        const demoContextParam = demoContext
          ? `&demo_context=${demoContext}`
          : '';
        const npath = window.location.pathname.replace('/', '');
        const path = npath || 'BASE';
        const afterLocation = path + params;
        const fullURL = `${signInPath}?location=${afterLocation}${demoContextParam}`;
        return <Redirect to={fullURL} />;
      }

      return <Component {...props} />;
    };

    return <Route {...rest} render={(props) => getRoute(props)} />;
  }

  PrivateRoute.propTypes = {
    component: func.isRequired,
    requiredRole: string,
  };

  PrivateRoute.defaultProps = {
    requiredRole: undefined,
  };

  return PrivateRoute;
}
export default PrivateRouteHoC;
