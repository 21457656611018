import React from 'react';

import ChannelSelect from 'app/Views/ChannelSelect';
import './styles.scss';

function SlackConfig() {
  return (
    <div className="config-container">
      <ChannelSelect />
    </div>
  );
}

export default SlackConfig;
