import { keyBy, pick } from 'lodash';

import Session from 'common/utils/Session';
import GetChannelsForTile from 'app/Services/TileChannel/getForTile';
import ConversationsList from 'app/Services/Slack/Conversations/List';

async function ConfigView(tileId, responseHandlers) {
  try {
    const userId = Session.get('id');
    const channelIds = await GetChannelsForTile(tileId);
    const channels = await ConversationsList(userId);
    const tileChannelHash = keyBy(channelIds);
    const channelsWithSelected = (channels || []).map((channel) => {
      const option = pick(channel, [
        'id',
        'description',
        'name',
        'totalMembers',
      ]);
      return {
        ...option,
        selected: Boolean(tileChannelHash[channel.id]),
      };
    });
    responseHandlers.onSuccess(channelsWithSelected);
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default ConfigView;
