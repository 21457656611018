/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { get, omit, without } from 'lodash';

const messageBatchToState = (messagesToLoad) => {
  const messagesIds = [];
  const messages = messagesToLoad.reduce((accum, message) => {
    messagesIds.push(message.id);
    return {
      ...accum,
      [message.id]: message,
    };
  }, {});
  return { messagesIds, messages };
};

const channelBatchToState = (channelsToLoad) => {
  const ids = [];
  const entities = channelsToLoad.reduce((accum, channel) => {
    const { messagesIds, messages } = messageBatchToState(
      get(channel, 'conversation.messages', []),
    );
    ids.push(channel.id);
    return {
      ...accum,
      [channel.id]: {
        ...omit(channel, 'conversation'),
        history: get(channel, 'conversation.history'),
        messages,
        messagesIds,
      },
    };
  }, {});
  return { ids, entities };
};

const historySlice = createSlice({
  name: 'channels',
  initialState: {
    ids: [],
    entities: {},
    active: null,
  },
  reducers: {
    clearActive: (state) => {
      state.active = null;
    },
    setActive: (state, action) => {
      if (get(action.payload, 'history.hasUnread')) {
        state.entities[action.payload.id].history.hasUnread = false;
      }
      state.active = action.payload.id;
    },
    addMessage: (state, action) => {
      const changes = action.payload;
      if (state.active !== changes.channelId) {
        state.entities[changes.channelId].history.hasUnread = true;
      }
      state.entities[changes.channelId].messages[changes.id] = changes;
      const newIds = [changes.id].concat(
        state.entities[changes.channelId].messagesIds,
      );
      state.entities[changes.channelId].messagesIds = newIds;
    },
    updateMessage: (state, action) => {
      const changes = action.payload;
      state.entities[changes.channelId].messages[changes.id] = changes;
    },
    deleteMessage: (state, action) => {
      const changes = action.payload;
      const currentMessages = state.entities[changes.channelId].messages;
      state.entities[changes.channelId].messages = omit(
        currentMessages,
        changes.id,
      );
      state.entities[changes.channelId].messagesId = without(
        state.entities[changes.channelId].messagesIds,
        changes.id,
      );
    },
    updateChannel: (state, action) => {
      const changes = action.payload;
      const channelToUpdate = state.entities[changes.id];
      state.entities[changes.id] = {
        ...channelToUpdate,
        ...changes,
      };
    },
    addChannelBatch: (state, action) => {
      const { ids, entities } = channelBatchToState(action.payload);
      state.ids = ids;
      state.entities = entities;
    },
  },
});

export const {
  addMessage,
  updateMessage,
  deleteMessage,
  addChannelBatch,
  updateChannel,
  setActive,
  clearActive,
} = historySlice.actions;

export default historySlice.reducer;
