import { slackAPI } from 'common/utils/Http';
import Channel from 'app/Services/Entities/Channel';

const UserConversation = async (userId) => {
  const response = await slackAPI.get({
    url: 'users.conversations',
    body: {
      exclude_archived: true,
      type: 'public_channel,private_channel',
      user: userId,
    },
  });
  const { channels } = response;
  return channels.map(Channel);
};

export default UserConversation;
