import React, { useEffect, useState } from 'react';
import { SortAlphaDown } from 'react-bootstrap-icons';
import cx from 'classnames';

import fromQS from 'common/utils/fromQS';
import Button from 'common/components/Buttons/Button';
import SearchInput from 'common/components/Inputs/Search';
import FadeIn from 'common/components/Animations/FadeIn';
import LoadingSpinner from 'common/components/LoadingSpinner';
import withServices from 'common/utils/withServices';
import PostConfigCompleted from 'app/Services/Events/PostConfigCompleted';

import AddTileChannel from 'app/Services/TileChannel/add';
import RemoveTileChannel from 'app/Services/TileChannel/remove';
import ConfigViewService from 'app/Services/Orchestration/ConfigView';

import { useRequest } from 'common/utils/Request';

import ChannelItem from './ChannelItem';

import './styles.scss';

const tileFromQS = fromQS('tile_id', true);
function ChannelSelect() {
  const [channels, setChannels] = useState([]);
  const channelsRequest = useRequest(ConfigViewService, {
    onSuccess: setChannels,
  });
  const addTileChannel = useRequest(AddTileChannel);
  const removeTileChannel = useRequest(RemoveTileChannel);

  useEffect(() => {
    channelsRequest.start(tileFromQS());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const add = (channel) => {
    addTileChannel.start({
      tile_id: tileFromQS(),
      channel_id: channel.id,
    });
  };

  const remove = (channel) => {
    removeTileChannel.start({
      tile_id: tileFromQS(),
      channel_id: channel.id,
    });
  };
  return (
    <div className="p-2">
      <div className="search-bar text-light">
        {false && (
          <SearchInput
            placeholder="Search channels"
            name="channels"
            onChange={() => {}}
            value=""
          />
        )}
      </div>

      <div className="filters d-flex justify-content-end align-items-center my-2">
        {false && (
          <div>
            <SortAlphaDown className="mr-2" size={20} />
          </div>
        )}
      </div>

      <div
        className={cx(
          'settings-items-container pt-2 pb-3 text-light rounded',
          (channelsRequest.isLoading || channelsRequest.hasError) &&
            'd-flex justify-content-center align-items-center',
        )}
      >
        {channelsRequest.isLoading && (
          <div className="text-white">
            <LoadingSpinner />
          </div>
        )}
        {channelsRequest.hasError && (
          <div className="px-3 text-danger">
            <h5 className="px-2">
              Something went wrong, could not load from source.
            </h5>
          </div>
        )}
        {!channelsRequest.isLoading && !channelsRequest.hasError && (
          <div className="d-block">
            {channels.map((channel, index) => (
              <FadeIn key={channel.id}>
                <ChannelItem
                  tabIndex={index}
                  channel={channel}
                  onSelect={add}
                  onRemove={remove}
                  selected={channel.selected}
                />
              </FadeIn>
            ))}
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end mt-3 mr-4 my-2">
        <Button variant="info" onClick={PostConfigCompleted}>
          Complete
        </Button>
      </div>
    </div>
  );
}

ChannelSelect.propTypes = {};

export default withServices({}, ChannelSelect);
