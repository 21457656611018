import { slackAPI } from 'common/utils/Http';

const PostMessage = async ({ channelId, message }, responseHandlers) => {
  try {
    const response = await slackAPI.post({
      url: 'chat.postMessage',
      body: {
        channel: channelId,
        text: message,
        as_user: 'true',
        // block: '',
      },
    });
    responseHandlers.onSuccess({
      data: response,
    });
  } catch (error) {
    responseHandlers.onError(error);
  }
};

export default PostMessage;
