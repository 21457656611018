/* eslint-disable import/no-named-as-default */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import cx from 'classnames';

import fromQS from 'common/utils/fromQS';
import { useRequest } from 'common/utils/Request';

import {
  getActiveChannel,
  getChannelCollection,
  getWksp,
} from 'app/Store/selectors';
import {
  addChannelBatch,
  addMessage,
  setActive,
  clearActive,
} from 'app/Store/channelSlice';
import { addUserBatch } from 'app/Store/userSlice';
import { setWorkspace } from 'app/Store/workspaceSlice';
import SlackSocket from 'app/Services/Slack/Socket';
import HomeDataLoadService from 'app/Services/Orchestration/HomeDataLoad';
import ChannelHeader from 'app/Views/ChannelHeader';
import ChannelMenu from 'app/Views/ChannelMenu';
import Conversation from 'app/Views/Conversation';
import './styles.scss';
import Chat from 'app/Views/Chat';
import MessageEntity from 'app/Services/Entities/Message';

const subscriptionId = 'homeListener';

function Home() {
  const wksp = useSelector(getWksp);
  const channels = useSelector(getChannelCollection);
  const activeChannel = useSelector(getActiveChannel);
  const dispatch = useDispatch();
  const homeRequest = useRequest(HomeDataLoadService, {
    onSuccess: (initialData) => {
      dispatch(addChannelBatch(initialData.channels));
      dispatch(addUserBatch(initialData.users));
      dispatch(setWorkspace(initialData.team));
      const channelId = fromQS('channel');
      if (channelId) {
        const findChannel = initialData.channels.find(
          (ch) => ch.id === channelId,
        );
        dispatch(setActive(findChannel));
      }
      SlackSocket.subscribe({
        id: subscriptionId,
        handler: (incoming) => {
          const event = get(incoming, 'event', {});
          if (event.type === 'message') {
            const messageEntity = MessageEntity(event);
            dispatch(addMessage(messageEntity));
          }
        },
      });
      SlackSocket.openConnection();
    },
  });
  useEffect(() => {
    homeRequest.start(fromQS('tile_id'));
    return () => {
      SlackSocket.unsubscribe(subscriptionId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectChannel = (channel) => dispatch(setActive(channel));
  const clearSelectedChannel = () => dispatch(clearActive());

  return (
    <div className="home-view bg-primary text-light">
      <div className="d-flex px-2 py-2 bg-dark position-relative justify-content-center home-app-header border-shadow-light" />
      <div className="d-flex home-app-container ">
        <div
          className={cx(
            'sidebar bg-primary',
            get(activeChannel, 'id') && 'sidebar-selected',
          )}
        >
          <div className="border-shadow-light text-white font-weight-bold px-3 py-3 d-flex align-items-center">
            {wksp.imageUrl && (
              <img
                className="profile-icon"
                src={wksp.imageUrl}
                alt="avatarUrl"
              />
            )}
            <span className="wksp-info ml-2">{wksp.name}</span>
          </div>
          <div>
            <ChannelMenu
              activeChannel={activeChannel}
              channels={channels}
              onChannelSelect={selectChannel}
            />
          </div>
        </div>
        {!isEmpty(activeChannel) ? (
          <div className="active-content bg-white">
            <div className="channel-header border-shadow-bottom-grey px-3 pt-2">
              <ChannelHeader
                name={get(activeChannel, 'name')}
                topic={get(activeChannel, 'topic')}
                onGoBack={clearSelectedChannel}
              />
            </div>
            <div className="active-conversation-container">
              <Conversation />
            </div>
            <div className="active-chat-container px-3 py-2">
              <Chat />
            </div>
          </div>
        ) : (
          <div className="d-none d-sm-flex flex-grow-1 center-content bg-white">
            <h4 className="px-3">Select a channel to start chatting!</h4>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
