import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import qs from 'query-string';

import { useRequest } from 'common/utils/Request';
import HttpStatus from 'common/components/HttpStatus';
import PageSpinner from 'common/components/PageSpinner';
import SlackAuth from 'app/Services/Slack/Auth';
import PostUserSigned from 'app/Services/Events/PostUserSigned';

function Redirect() {
  const loginRequest = useRequest(SlackAuth, {
    onSuccess: () => {
      PostUserSigned();
    },
    onError: () => {},
  });

  useEffect(() => {
    const params = qs.parse(window.location.search);
    const code = params.code || null;
    // const state = params.state || null;
    loginRequest.start({ code });
  }, []);

  return (
    <div>
      {loginRequest.inProcess && <PageSpinner />}
      {!isEmpty(loginRequest.error) && (
        <HttpStatus status="401" title="Could not authenticate" />
      )}
    </div>
  );
}

export default Redirect;
