import { number, shape, string } from 'prop-types';

const ChannelItemType = shape({
  description: string,
  id: string.isRequired,
  totalMembers: number.isRequired,
  name: string.isRequired,
});

export default ChannelItemType;
