function PostSignInRequest(loginPath) {
  const fullPath = `${window.location.origin}${loginPath}`;
  const message = {
    event: 'PLUGIN_SIGN_REQUEST',
    payload: {
      url: fullPath,
    },
  };
  if (window.parent) {
    window.parent.postMessage(message, '*');
  }
}

export default PostSignInRequest;
