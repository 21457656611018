import { slackAPI } from 'common/utils/Http';
import Conversation from 'app/Services/Entities/Conversation';

const ConversationHistory = async (id) => {
  const response = await slackAPI.post({
    url: 'conversations.history',
    body: {
      channel: id,
      limit: '50',
      unreads: 'true',
    },
  });

  const conversation = Conversation(response, id);
  return conversation;
};

export default ConversationHistory;
