import uuid from 'common/utils/uuid';

function Message(slackMessage, channelIdRelated) {
  const channelId = slackMessage.channel || channelIdRelated;
  if (!channelId) throw new Error(`Map error, missing ${channelId}`);
  return {
    id: slackMessage.client_msg_id || uuid(),
    channelId,
    clientMessageId: slackMessage.client_msg_id,
    type: slackMessage.type,
    subtype: slackMessage.subtype,
    rawText: slackMessage.text,
    ts: slackMessage.ts,
    team: slackMessage.team,
    userId: slackMessage.user,
    topic: slackMessage.topic,
    inviterId: slackMessage.inviter,
  };
}

export default Message;
