import React from 'react';
import { arrayOf, func, shape } from 'prop-types';

import MenuItem from './components/MenuItem';

function Channels({ activeChannel, channels, onChannelSelect }) {
  return (
    <div id="home">
      <div className="pl-3 pt-3 pb-1">Channels</div>
      <div>
        {(channels || []).map((channel) => (
          <MenuItem
            key={channel.id}
            isActive={activeChannel.id === channel.id}
            name={channel.name}
            value={channel}
            onClick={onChannelSelect}
          />
        ))}
      </div>
    </div>
  );
}

Channels.propTypes = {
  activeChannel: shape({}),
  channels: arrayOf(shape({})).isRequired,
  onChannelSelect: func.isRequired,
};

Channels.defaultProps = {
  activeChannel: null,
};

export default Channels;
