import { slackAPI } from 'common/utils/Http';
import Channel from 'app/Services/Entities/Channel';

const ConversationsList = async () => {
  const response = await slackAPI.post({
    url: 'conversations.list',
  });
  const { channels } = response;
  return channels.map(Channel);
};

export default ConversationsList;
