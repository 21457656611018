/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { bool, func } from 'prop-types';
import cx from 'classnames';
import useToggleState from 'common/utils/useToggleState';
import Button from 'common/components/Buttons/Button';
import { Check, X } from 'common/icons';
import ChannelItemType from './ChannelItemType';
import './styles.scss';

function ChannelItem({ channel, onRemove, onSelect, selected }) {
  const handleToggle = (latestIsSelected) => {
    if (latestIsSelected) {
      onSelect(channel);
    } else {
      onRemove(channel);
    }
  };

  const isSelected = useToggleState(selected, {
    listeners: { onToggle: handleToggle },
  });

  return (
    <div
      onClick={isSelected.onToggle}
      className={cx(
        'item-container py-2 px-2 border-shadow-light cursor-pointer',
        isSelected.isActive && 'selected',
      )}
    >
      <div className="d-flex justify-content-start align-items-center border-shadow-dark pb-2">
        <Button
          onClick={isSelected.onToggle}
          variant={isSelected.isActive ? 'danger' : 'success'}
          className="cx-content-action btn-select mr-2 text-white"
        >
          {isSelected.isActive ? <X /> : <Check />}
        </Button>
        <div
          className={cx(
            'text-white',
            isSelected.isActive && 'font-weight-bold',
          )}
        >{`#${channel.name}`}</div>
      </div>
      <div className="d-flex align-items-center py-1 ml-2">
        <div className="item-members-amount">{channel.totalMembers}</div>
        <div className="item-members mr-2">members</div>
        <div className="item-description justify-content-center mr-4">
          {channel.description
            ? `${channel.description.substring(0, 100)}...`
            : 'No Description'}
        </div>
      </div>
    </div>
  );
}

ChannelItem.propTypes = {
  channel: ChannelItemType.isRequired,
  onRemove: func.isRequired,
  onSelect: func.isRequired,
  selected: bool,
};

ChannelItem.defaultProps = {
  selected: false,
};

export default ChannelItem;
