import React from 'react';
import { string } from 'prop-types';

import { timeFromTimestamp } from 'common/utils/dates';
import './styles.scss';

function Message({ userId, username, text, ts, avatarUrl }) {
  return (
    <div className="message-day py-3">
      <div className="message-container px-3 py-1">
        <div className="d-flex message">
          <div className="mr-2 mt-1">
            <img className="profile-icon" src={avatarUrl} alt="avatarUrl" />
          </div>
          <div>
            <div className="d-flex align-items-center">
              <div className="mx-1">
                <b>{username}</b>
              </div>
              <div className="message-time">{timeFromTimestamp(ts)}</div>
            </div>
            <div style={{ maxWidth: '600px' }} className="ml-1">
              {text.replace(`<@${userId}>`, '')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Message.propTypes = {
  avatarUrl: string.isRequired,
  text: string.isRequired,
  ts: string.isRequired,
  userId: string.isRequired,
  username: string.isRequired,
};

Message.defaultProps = {};

export default Message;
