/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const wkspSlice = createSlice({
  name: 'workspace',
  initialState: {
    id: '',
    name: '',
    imageUrl: '',
  },
  reducers: {
    setWorkspace: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.imageUrl = action.payload.imageUrl;
    },
  },
});

export const { setWorkspace } = wkspSlice.actions;

export default wkspSlice.reducer;
