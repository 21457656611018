/* eslint-disable camelcase */
import session from 'common/utils/Session';

const demoUserHash = {
  daria: {
    token:
      'xoxp-1700030696210-1827333232034-1833692912644-e1658ae7fe192ce8076e358a1ae1a1fa',
    id: 'U01QB9T6U10',
    type: 'user',
    scope:
      'channels:history,channels:read,groups:read,team:read,users:read,usergroups:read,channels:write,chat:write',
    teamId: 'T01LL0WLG66',
    teamName: 'unisoft',
  },
};

const DemoAuthorize = (demoUser, cb) => {
  const hardcodedUser = demoUserHash[demoUser] || demoUserHash.daria;
  session.save(hardcodedUser);
  cb();
};

export default DemoAuthorize;
