/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import cx from 'classnames';

import './styles.scss';

function MenuItem({ isActive, value, name, onClick }) {
  const handleClick = () => {
    onClick(value);
  };
  const itemClasses = isActive ? 'bg-info text-white' : 'menu-item';
  // ToDo check styles bg-primary is not working
  return (
    <div
      onClick={handleClick}
      className={cx(
        `channel-item ${itemClasses}`,
        value.history.hasUnread && 'text-white font-weight-bold',
      )}
    >
      <div className="px-4 py-1">{`# ${name}`}</div>
    </div>
  );
}

MenuItem.propTypes = {
  isActive: bool,
  name: string.isRequired,
  onClick: func.isRequired,
  value: shape({}).isRequired,
};

MenuItem.defaultProps = {
  isActive: false,
};

export default MenuItem;
