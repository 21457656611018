const defaultGlobalEnvs = {
  domain: process.env.REACT_APP_SLACK_API,
  configAPI: process.env.REACT_APP_CONFIG_API,
  env: process.env.REACT_APP_ENV,
  slackId: process.env.REACT_APP_SLACK_ID,
  slackSecret: process.env.REACT_APP_SLACK_SECRET,
  slackOAUTH: process.env.REACT_APP_SLACK_OAUTH,
  slackSocketModeToken: process.env.REACT_APP_SLACK_SOCKET_MODE_TOKEN,
};

class Config {
  constructor() {
    this.envs = defaultGlobalEnvs;
  }

  get = (key) => {
    return this.envs[key];
  };
}

export default new Config();
