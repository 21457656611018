/* eslint-disable no-param-reassign */
import { isFunction, get } from 'lodash';

function requestTemplateInjector(client, baseURL) {
  const _template = (method) => ({ url, body, options = {} }) => {
    const httpParams = [];
    const config = {
      ...options,
    };
    httpParams.push(`${baseURL}${url}`);
    if (body) {
      httpParams.push(body);
    }
    httpParams.push(config);
    if (
      isFunction(get(client, '_interceptors.requestRefresh')) &&
      isFunction(get(client, '_interceptors.response401'))
    ) {
      return client._interceptors
        .requestRefresh()
        .then(() => {
          return client._axios[method](...httpParams).catch(
            client._interceptors.response401,
          );
        })
        .catch(client._interceptors.response401);
    }
    return client._axios[method](...httpParams).catch((error) => {
      if (get(client, '_interceptors.response401')) {
        client._interceptors.response401(error);
      }
    });
  };

  client.get = _template('get');

  client.post = _template('post');

  client.put = _template('put');

  client.patch = _template('patch');

  client.delete = _template('delete');
}

export default requestTemplateInjector;
