import React, { useEffect } from 'react';
import PageSpinner from 'common/components/PageSpinner';
import appConfig from 'Config';

const userScopes = [
  'channels:history',
  // 'channels:join',
  'channels:read',
  'channels:write',
  'chat:write',
  'groups:read',
  'usergroups:read',
  'users:read',
];
const clientId = appConfig.get('slackId');
const redirectUrl = appConfig.get('slackOAUTH');
const urlScopes = userScopes.join(',');
const AUTH_URL = `https://slack.com/oauth/v2/authorize?user_scope=${urlScopes}&client_id=${clientId}&redirect_uri=${redirectUrl}`;
function OAuthPermission() {
  useEffect(() => {
    window.location.href = AUTH_URL;
  }, []);

  return (
    <div>
      <PageSpinner />
    </div>
  );
}

export default OAuthPermission;
