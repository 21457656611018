import appConfig from 'Config';
import { configAPI } from 'common/utils/Http';
import Observable from 'common/utils/Observable';

function Socket() {
  Observable.call(this);
  const _self = this;
  let webSocket;
  _self.openConnection = async () => {
    if (webSocket) return;
    try {
      const token = appConfig.get('slackSocketModeToken');

      const { data: response } = await configAPI.get({
        url: 'plugins/slack/connection',
        options: {
          headers: { Token: token },
        },
      });

      const socketUrl = response.url;
      webSocket = new WebSocket(socketUrl);
      webSocket.onopen = (e) => console.log('ws opened', e);
      webSocket.onclose = () => {
        setTimeout(_self.openConnection, 1000);
      };
      webSocket.onmessage = (event) => {
        const data = JSON.parse(event.data) || {};
        if (data.retry_attempt > 0) {
          return;
        }
        webSocket.send(JSON.stringify({ envelope_id: event.data.envelope_id }));
        if (data.payload) {
          _self.emit(data.payload);
        }
      };
    } catch (error) {
      console.error(error);
    }
  };
}

const socket = new Socket();
export default socket;
