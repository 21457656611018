/**
 * Import npm dependencies
 */
import axios from 'axios';
import appConfig from 'Config';

import requestTemplateInjector from './axiosrequestTemplateInjector';

class ConfigClient {
  constructor() {
    this._axios = axios.create();
    const self = this;
    requestTemplateInjector(self, appConfig.get('configAPI'));
  }
}

export default ConfigClient;
